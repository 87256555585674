import dayjs from '~/lib/useDates'
import { formatAsDateISO } from '~/utils/format-dates'

// Day of Week - https://day.js.org/docs/en/get-set/day
export const selectMidweekDates = (start?: Date | string, end?: Date | string): { start: string, end: string } => {
  if (!start || !end) {
    const startResult = dayjs().add(1, 'week').day(1)
    const endResult = dayjs(startResult).add(4, 'day')

    return {
      start: formatAsDateISO(startResult),
      end: formatAsDateISO(endResult),
    }
  }

  const sunday = 0
  const saturday = 6
  const weekend = [sunday, saturday]
  const startWeekday = Number(dayjs(start).format('d'))
  const endWeekday = Number(dayjs(end).format('d'))
  const startDate = dayjs(start)
  const endDate = dayjs(end)

  let isFuture = true

  const diff = Math.abs(startDate.diff(endDate, 'day'))

  // Big ranges get the last midweek
  if (diff >= 7) {
    const start = endDate.day(1)
    const end = dayjs(start).add(4, 'day')
    return { start: formatAsDateISO(start), end: formatAsDateISO(end) }
  }

  // Start as a saturday cannot select the future
  // Or start as a weekday and end as a weekend cannot select the future
  if (
    startWeekday === saturday
    || (!weekend.includes(startWeekday) && weekend.includes(endWeekday))
  ) {
    isFuture = false
  }

  let startResult = dayjs(start)
    .add(isFuture ? 1 : 0, 'week')
    .day(1)

  // Avoid selecting disabled dates
  if (dayjs(startResult).isBefore(dayjs())) {
    startResult = dayjs().add(1, 'week').day(1)
  }

  const endResut = dayjs(startResult).add(4, 'day')

  return {
    start: formatAsDateISO(startResult),
    end: formatAsDateISO(endResut),
  }
}

export const isMidweekSelected = (start?: Date | string, end?: Date | string): boolean => {
  if (!start || !end) return false

  const monday = 1
  const startWeekday = Number(dayjs(start).format('d'))
  const diff = dayjs(end).diff(dayjs(start), 'day')

  return startWeekday === monday && diff === 4
}
