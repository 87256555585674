<template>
  <div class="quick-filters">
    <nav
      ref="navFilters"
      @scroll="navScroll($event)"
    >
      <ul>
        <li
          v-for="item of items"
          :key="item.slug"
        >
          <button
            type="button"
            :data-testid="`${item.slug}-filter`"
            :class="[
              { 'large-filter': largeItems.includes(item.slug) },
              { 'small-filter': smallItems.includes(item.slug) },
              { active: isActive(item.slug) },
            ]"
            @click="
              updateFilter(item.slug)
            "
          >
            <fa :icon="item.icon" /><br>
            <span>{{ t(item.slug) }}</span>
          </button>
          <div class="line" />
        </li>
      </ul>

      <ClientOnly>
        <template v-if="$device.isDesktop">
          <Transition name="quick-fade">
            <div
              v-if="!isStartScroll"
              class="arrows prev"
            >
              <button
                type="button"
                @click.once="scrollToStart"
              >
                <fa :icon="['fas', 'chevron-left']" />
              </button>
            </div>
          </Transition>

          <Transition name="quick-fade">
            <div
              v-if="!isEndScroll"
              class="arrows next"
            >
              <button
                type="button"
                @click.once="scrollToEnd"
              >
                <fa :icon="['fas', 'chevron-right']" />
              </button>
            </div>
          </Transition>
        </template>
        <template v-else>
          <Transition name="fade">
            <div
              v-if="scrollPosition === 0"
              class="edge-shadow"
            />
          </Transition>
        </template>
      </ClientOnly>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { QuickFilters } from '~/lib/enums/frontendEnums'
import { isBudgetFriendlySelected } from '~/lib/search'
import { BUDGET_FRIENDLY_MIN_PRICE, BUDGET_FRIENDLY_MAX_PRICE } from '~/constants/search'
import type { SearchFilters } from '~/types/search'

const { $search } = useNuxtApp()
const route = useRoute()
const { t } = useI18n()

const navFilters = ref<HTMLDivElement | null>(null)
const isStartScroll = ref(true)
const isEndScroll = ref(true)
const scrollPosition = ref(0)

type QuickFiltersItems = {
  slug: string
  icon: [collection: string, name: string]
}

const items = computed((): QuickFiltersItems[] => [
  { slug: QuickFilters.Delivery, icon: ['fal', 'truck-fast'] },
  { slug: QuickFilters.MidweekDeals, icon: ['fal', 'calendar-range'] },
  { slug: QuickFilters.BudgetFriendly, icon: ['fal', 'piggy-bank'] },
  { slug: QuickFilters.PetFriendly, icon: ['fal', 'paw-simple'] },
  { slug: QuickFilters.FamilyFriendly, icon: ['fal', 'family-pants'] },
  { slug: QuickFilters.FestivalFriendly, icon: ['fal', 'calendar-star'] },
  { slug: QuickFilters.InstantBook, icon: ['fal', 'bolt'] },
])

const largeItems = computed(() => [
  QuickFilters.MidweekDeals,
  QuickFilters.BudgetFriendly,
])

const smallItems = computed(() => [
  QuickFilters.Delivery,
  QuickFilters.PetFriendly,
  QuickFilters.FestivalFriendly,
])

onMounted(() => {
  window.addEventListener('resize', resizeWindow)
  initScroll()

  if (route.query?.MidweekDeals === 'true') {
    midweekDealsFilter()
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeWindow)
})

function resizeWindow() {
  initScroll()
}

function budgetFriendlyFilter() {
  $search.updateFilter(
    'rvPrice',
    isActive(QuickFilters.BudgetFriendly)
      ? { min: undefined, max: undefined }
      : { min: BUDGET_FRIENDLY_MIN_PRICE, max: BUDGET_FRIENDLY_MAX_PRICE },
  )
}

function familyFriendlyFilter() {
  if (!$search.parameters.filters.amenities?.Family_kidfriendly) {
    $search.parameters.filters.amenities = {
      ...$search.parameters.filters.amenities,
      Family_kidfriendly: false,
    }
  }

  $search.parameters.filters.amenities.Family_kidfriendly = !isActive(
    QuickFilters.FamilyFriendly,
  )
  $search.updateFilter('amenities', $search.parameters.filters.amenities)
}

function midweekDealsFilter() {
  if (isActive(QuickFilters.MidweekDeals)) {
    $search.updateDates({ start: undefined, end: undefined })
    return
  }

  const result = selectMidweekDates(
    route.query?.StartDate as string || undefined,
    route.query?.EndDate as string || undefined,
  )

  $search.updateDates({ start: result.start, end: result.end })
}

function updateFilter(slug: string) {
  if (slug === QuickFilters.BudgetFriendly) {
    budgetFriendlyFilter()
    return
  }

  if (slug === QuickFilters.FamilyFriendly) {
    familyFriendlyFilter()
    return
  }

  if (slug === QuickFilters.MidweekDeals) {
    midweekDealsFilter()
    return
  }

  const filter = slug as keyof SearchFilters
  $search.updateFilter(filter, Boolean(!$search.parameters.filters[filter]))
}

function isActive(slug: string): boolean {
  if (slug === QuickFilters.BudgetFriendly) {
    return isBudgetFriendlySelected(
      $search.parameters?.filters?.rvPrice?.min,
      $search.parameters?.filters?.rvPrice?.max,
    )
  }

  if (slug === QuickFilters.MidweekDeals) {
    return isMidweekSelected(String(route.query?.StartDate), String(route.query?.EndDate))
  }

  if (slug === QuickFilters.FamilyFriendly) {
    return Boolean($search.parameters.filters.amenities?.Family_kidfriendly)
  }

  const filter = slug as keyof SearchFilters
  return Boolean($search.parameters.filters[filter]) || false
}

function navScroll($event: Event) {
  isStartScroll.value = false
  isEndScroll.value = false

  const { scrollLeft, scrollWidth, offsetWidth } = $event.target as HTMLElement

  scrollPosition.value = scrollLeft

  if (scrollLeft === 0) {
    isStartScroll.value = true
    return
  }

  const diff = scrollWidth - offsetWidth
  const scroll = Math.round(scrollLeft)

  if (scroll === diff || scroll + 1 === diff || scroll - 1 === diff) {
    isEndScroll.value = true
  }
}

function initScroll() {
  scrollToStart()
  isEndScroll.value = Number(navFilters?.value?.scrollWidth) > Number(navFilters.value?.clientWidth) ? false : true
}

function scrollToStart() {
  navFilters.value?.scrollTo({ left: 0, behavior: 'smooth' })
}

function scrollToEnd() {
  navFilters.value?.scrollTo({
    left: navFilters.value?.scrollWidth,
    behavior: 'smooth',
  })
}
</script>

<style lang="scss" scoped>
.quick-fade-enter-active,
.quick-fade-leave-active {
  transition: opacity 0.25s ease;
}

.quick-fade-enter-from,
.quick-fade-leave-to {
  opacity: 0;
}

.quick-filters {
  position: relative;

  nav::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
    background: transparent;
  }

  nav {
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;

    @media only screen and (max-width: 1410px) {
      width: 40rem;
    }

    @media only screen and (max-width: 1250px) {
      width: 31rem;
    }

    // table and mobile
    @include media-max-size(large) {
      width: calc(100vw - 2rem);
    }

    // small mobile
    @include media-max-size(xSmall) {
      width: calc(100vw - 3rem);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @include media-max-size(small) {
        gap: 0.25rem;
      }

      li {
        text-align: center;
        position: relative;

        button {
          min-width: 6.875rem;
          height: 4.875rem;
          display: inline-block;
          background-color: #fff;
          border: none;
          padding: 0.7rem 0;

          svg,
          span {
            color: getColor("primary-350");
          }

          svg {
            height: 1.5rem;
          }

          span {
            @include captionBase;
          }

          &.large-filter {
            min-width: 7.3125rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          &.small-filter {
            min-width: 5.5rem;
          }

          + .line {
            position: absolute;
            width: 100%;
            border-bottom: 0.125rem solid transparent;
            bottom: 0;
          }

          &.active {
            background-color: getColor("highlight-50");

            svg,
            span {
              color: getColor("primary-500");
            }

            + .line {
              border-color: getColor("highlight-500");
            }
          }
        }

        @include media-min-size(large) {
          button:hover {
            background-color: getColor("highlight-50");
          }
        }
      }
    }
  }

  .arrows {
    position: absolute;
    top: 0;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.prev {
      left: 0;
      background: linear-gradient(
        to left,
        rgba($color: #fff, $alpha: 0.5),
        #fff
      );
    }

    &.next {
      right: 0;
      background: linear-gradient(
        to left,
        #fff,
        rgba($color: #fff, $alpha: 0.5)
      );
    }

    &.prev,
    &.next {
      button {
        background-color: #fff;
        border: none;
        width: 2.625rem;
        height: 2.625rem;
        box-shadow: 0 0 0.75rem
          rgba($color: getColor("primary-800"), $alpha: 0.2);
        border-radius: 50%;

        svg {
          color: getColor("primary-500");
          font-size: 1rem;
        }
      }
    }
  }

  .edge-shadow {
    position: absolute;
    top: 0;
    right: -1rem;
    width: 3rem;
    height: 100%;
    background: linear-gradient(to left, #fff, rgba($color: #fff, $alpha: 0.5));
  }
}

// mobile and tablet
@include media-max-size(small) {
  .quick-filters nav ul li {
    button {
      svg {
        height: 1.25rem;
      }

      span {
        font-size: 0.6875rem;
      }

      &.large-filter {
        min-width: 6.875rem;
        margin-left: 0;
        margin-right: 0;
      }
    }

    button {
      min-width: auto;
      padding: 0 0.5rem;
      height: 3.5rem;
    }

    button {
      &.large-filter,
      &.small-filter {
        min-width: auto;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "delivery": "Delivery",
    "midweekDeals": "Midweek Deals",
    "budgetFriendly": "Budget Friendly",
    "petFriendly": "Pet Friendly",
    "familyFriendly": "Family Friendly",
    "festivalFriendly": "Festivals",
    "instantBook": "Instant Book"
  },
  "fr": {
    "delivery": "Livraison",
    "midweekDeals": "Promo lun-ven",
    "budgetFriendly": "Économique",
    "petFriendly": "Animaux admis",
    "familyFriendly": "Pour famille",
    "festivalFriendly": "Festivals",
    "instantBook": "Instant Book"
  }
}
</i18n>
